<template>
	<div class="battery-container">
		<jy-query ref="searchForm" :model="searchForm">
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="searchForm.vehicleNo"></el-input>
			</jy-query-item>
			<!-- <jy-query-item label="所属机构:" prop="orgNa">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="searchForm.orgNa"></el-input>
				</div>
			</jy-query-item> -->
			<jy-query-item prop="vin" label="VIN码:">
				<el-input placeholder="请输入" v-model="searchForm.vin"></el-input>
			</jy-query-item>
			<jy-query-item prop="time" label="报警时间:">
				<el-date-picker
					v-model="searchForm.time"
					type="daterange"
					placeholder="请选择"
					:clearable="false"
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					:picker-options="pickOptions"
				>
				</el-date-picker>
			</jy-query-item>
			<jy-query-item prop="vehicleAlarmRuleId" label="报警名称:">
				<el-select v-model="searchForm.vehicleAlarmRuleId" filterable>
					<el-option v-for="(item, index) in alarmOptions" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<!-- <el-input placeholder="请输入" v-model="searchForm.vehicleAlarmRuleName"></el-input> -->
			</jy-query-item>
			<template slot="search">
				<el-button type="primary" @click="searchHandle" v-if="btnexist('scheduleSecureBatteryList')">查询</el-button>
				<el-button type="primary" plain @click="resetForm('searchForm')" v-if="btnexist('scheduleSecureBatteryReset')"
					>重置</el-button
				>
				<!-- <el-row>
				</el-row> -->
			</template>
			<template slot="operate">
				<el-button
					type="primary"
					@click="exportHandle"
					v-if="btnexist('scheduleSecureBatteryExport')"
					:disabled="!tableList.length"
					>导出</el-button
				>
			</template>
		</jy-query>
		<jy-table max-height="595" ref="jyTable" :data="tableList">
			<jy-table-column type="index" width="60" label="序号" fixed="left" align="center"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="120"></jy-table-column>
			<jy-table-column prop="vin" label="车架号" min-width="180"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="220"></jy-table-column>
			<jy-table-column prop="routeName" label="所属线路" min-width="160"></jy-table-column>
			<jy-table-column prop="vehicleAlarmRuleName" label="报警名称" min-width="200"></jy-table-column>
			<jy-table-column prop="level" label="故障等级" min-width="60">
				<template v-slot="{ row }">
					{{ alarmLevelEnum[row.level] }}
				</template>
			</jy-table-column>
			<jy-table-column prop="startTime" label="开始时间" min-width="200"></jy-table-column>
			<jy-table-column prop="endTime" label="结束时间" min-width="200"></jy-table-column>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="page.pageNum"
			:page-size="page.pageSize"
			:total="total"
		></jy-pagination>
		<!-- <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree> -->
	</div>
</template>

<script>
// import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import { btnMixins } from "@/common/js/button.mixin";
import dayjs from "dayjs";
import { alarmLevelEnum } from "../enum";

export default {
	data() {
		return {
			searchForm: {
				vehicleNo: "",
				// orgNa: "",
				// orgId: "",
				vin: "",
				time: [],
				vehicleAlarmRuleId: ""
				// alarmCodes: []
			},
			tableList: [],
			page: {
				pageSize: 10,
				pageNum: 1
			},
			total: 0,
			alarmLevelEnum,
			btnMenuId: "scheduleSecureBattery",
			pickOptions: {
				onPick({ maxDate, minDate }) {
					const max = dayjs(maxDate);
					const min = dayjs(minDate);
					// 开始日期0点 结束日期24点，所以是6
					if (max.subtract(6, "day") > min) {
						this.$message({
							message: "只支持查询7天的数据",
							type: "warning"
						});
					}
				}
			},
			alarmOptions: []
		};
	},
	/* components: {
		institutionsTree
	}, */
	mixins: [btnMixins],
	created() {
		this.setDefaultSearchTime();
		this.getOptions();
		this.getTableList();
	},
	methods: {
		// 默认查询时间
		setDefaultSearchTime() {
			const cDay = dayjs().format("YYYY-MM-DD");
			this.searchForm.time = [cDay, cDay];
		},
		getOptions() {
			const url = `/crrc-palm-bus/safetyManagement/alarmRule/list`;
			this.$http.post(url, { pageNum: 1, pageSize: 999 }, { baseType: "base" }).then(res => {
				this.alarmOptions = res?.list || [];
			});
		},
		getParams() {
			const [startTime = "", endTime = ""] = this.searchForm.time || [];
			const params = {
				...this.searchForm,
				...this.page,
				// orgIds: this.searchForm.orgId ? [this.searchForm.orgId] : [],
				startTime: `${startTime} 00:00:00`,
				endTime: `${endTime} 23:59:59`
			};
			delete params.time;
			// delete params.orgId;
			return params;
		},
		async getTableList() {
			const [startTime = "", endTime = ""] = this.searchForm.time || [];
			// 开始日期0点 结束日期24点，所以是6
			if (dayjs(endTime).subtract(6, "day") > dayjs(startTime)) {
				this.$message({
					message: "只支持查询7天的数据",
					type: "warning"
				});
				return;
			}
			const params = this.getParams();
			const url = `/crrc-palm-bus/safetyManagement/batteryWarning/list`;
			this.$http.post(url, params, { baseType: "base" }).then(res => {
				this.tableList = res.list || [];
				this.total = res.total || 0;
			});
		},
		searchHandle() {
			this.page.pageNum = 1;
			this.getTableList();
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			// this.searchForm.orgId = "";
			this.page.pageNum = 1;
			this.getTableList();
		},
		exportHandle() {
			const params = this.getParams();
			const url = `/crrc-palm-bus/safetyManagement/batteryWarning/export`;
			this.$http.post(url, params, { baseType: "base", isExcel: true }).then(res => {
				if (res.status == 200) {
					this.$util.funDownload(res.data, "电池预警信息");
				} else {
					this.$message({
						message: "导出失败",
						type: "warning"
					});
				}
			});
		},
		// 所属机构
		addInstitutions(data) {
			console.log(data);
			this.searchForm.orgId = data.orgId;
			this.searchForm.orgNa = data.orgNa;
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		// 分页
		handleSizeChange(val) {
			this.page.pageNum = 1;
			this.page.pageSize = val;
			this.getTableList();
		},
		handleCurrentChange(val) {
			this.page.pageNum = val;
			this.getTableList();
		}
	}
};
</script>
